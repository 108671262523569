export default {
  mounted: (element, binding) => {
    if (!('open' in binding.value)) binding.value.open = true
    if (!('closable' in binding.value)) binding.value.open = true

    const onEscape = (e) => {
      if (binding.value.open && binding.value.closable && e.keyCode === 27) {
        element.dispatchEvent(new Event('escape'))
      }
    }

    if (!import.meta.env.SSR) {
      document.addEventListener('keydown', onEscape)
    }
  },
}
