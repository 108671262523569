<script setup>
import { useSlots, watch, ref, toRef, onMounted, onBeforeUnmount, nextTick } from 'vue'
import Button from '@/components/partials/Buttons/Button/Button.vue'
import { useStore } from '@/stores'
import vCloseOnEscape from '@/plugins/directives/close_on_escape'

const slots = useSlots()
const store = useStore()

const emit = defineEmits(['close'])

const props = defineProps({
  animated: { type: Boolean, default: true },
  closable: { type: Boolean, default: true },
  open: { type: Boolean, default: false },
  hasSeoContent: { type: Boolean, default: false },
  noPadding: { type: Boolean, default: false },
  dataTestId: { type: String, default: 'modal' },
})

const open = toRef(props, 'open')
const container = ref(null)
const closeBtn = ref(null)
const header = ref(null)
const bottom = ref(null)
const RO = ref(null)

const draggableTrigger = ref(null)
const draggableTarget = ref(null)
const scrollable = ref(null)

const maxContentHeight = ref(0)

const close = () => {
  if (props.closable) emit('close')
}

const closeOnDrag = () => {
  let touchStartY = 0
  let touchStartScroll = 0
  const minDistance = 16 * 3 // 3rem

  const handleTouchStart = (e) => {
    touchStartY = e.changedTouches[0].screenY
    touchStartScroll = scrollable.value.scrollTop
    draggableTarget.value.removeAttribute('draggable-released', true)
  }

  const handleTouchMove = (e) => {
    const touchY = e.changedTouches[0].screenY
    const movement = touchY - touchStartY

    // disable on modals that cannot be closed
    if (!props.closable) return

    // if the content of the modal is not scrolled to the top, stop the animation
    if (touchStartScroll !== 0) return

    // animate dragging down
    if (draggableTarget.value?.style) draggableTarget.value.style.transform = `translateY(${
      // limit movement to downwards movement only
      movement > 0 ? movement : 0
    }px)`

    // prevent scrolling
    if (movement > 0) scrollable.value.scrollTop = 0
  }

  const handleTouchEnd = (e) => {
    const touchendY = e.changedTouches[0].screenY

    if (
      // make sure the content of the modal is scrolled to the top
      touchStartScroll === 0 &&
      // check if the gesture is a swipe down
      touchendY > touchStartY &&
      // check if the distance is sufficient enough
      Math.abs(touchStartY - touchendY) >= minDistance &&
      // check if the modal can be closed
      props.closable
    ) {
      draggableTarget.value.setAttribute('draggable-released', true)

      if (draggableTarget.value?.style)
        draggableTarget.value.style.transform = `translateY(${
          draggableTarget.value.getBoundingClientRect().height
        }px)`

      // close modal after animation
      setTimeout(close, 200)
    } else {
      // limit movement to downwards movement only
      if (draggableTarget.value?.style) draggableTarget.value.style.transform = `translateY(0px)`
      // add css transition
      draggableTarget.value.setAttribute('draggable-released', true)
    }
  }

  if (!draggableTrigger.value) return

  draggableTrigger.value.addEventListener('touchstart', handleTouchStart)
  draggableTrigger.value.addEventListener('touchmove', handleTouchMove)
  draggableTrigger.value.addEventListener('touchend', handleTouchEnd)
}

const calculateMaxContentHeight = () => {
  if (!container.value) return

  const safeInsetTop = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sat")) || 0
  const safeInsetBottom = parseInt(getComputedStyle(document.documentElement).getPropertyValue("--sab")) || 0

  const containerCS = getComputedStyle(container.value)
  const containerInnerHeight = window.innerHeight - parseInt(containerCS.getPropertyValue('padding-top')) - parseInt(containerCS.getPropertyValue('padding-bottom'))
  const headerHeight = header.value ? header.value.getBoundingClientRect().height : 0
  const bottomHeight = bottom.value ? bottom.value.getBoundingClientRect().height : 0

  const maxHeightInPixels = containerInnerHeight - safeInsetTop - safeInsetBottom - headerHeight - bottomHeight
  maxContentHeight.value = `${maxHeightInPixels}px`
}

const initializeModal = () => {
  closeOnDrag()
  calculateMaxContentHeight()

  // Resize if the window resizes
  window.addEventListener('resize', calculateMaxContentHeight)

  // Resize if the header or bottom slot changes in height
  RO.value = {
    header: new ResizeObserver(calculateMaxContentHeight),
    bottom: new ResizeObserver(calculateMaxContentHeight),
    content: new ResizeObserver(calculateMaxContentHeight),
  }

  if (header.value) RO.value.header.observe(header.value)
  if (bottom.value) RO.value.bottom.observe(bottom.value)

  RO.value.content.observe(scrollable.value)
}

const breakdownModal = () => {
  window.removeEventListener('resize', calculateMaxContentHeight)

  if (RO.value) {
    Object.keys(RO.value).forEach((key) => {
      RO.value[key].disconnect()
    })
  }
}

onMounted(() => {
  nextTick(() => {
    if (open.value) {
      initializeModal()
    }
  })
})

onBeforeUnmount(breakdownModal)

watch(open, (bool) => {
  if (bool) {
    nextTick(initializeModal)
  } else {
    breakdownModal()
  }
})
</script>

<template>
  <div>
    <Teleport to="#teleports">
      <div v-if="open"
           ref="container"
           v-bind="$attrs"
           v-body-scroll-lock="open"
           v-close-on-escape="{ open, closable }"
           class="modal"
           :class="{ 'modal--no-padding': noPadding }"
           @escape="close"
      >
        <div class="modal__overlay"
             :class="{
               animation__overlay: animated,
             }"
             @click="close"
        />

        <div class="modal__container"
             :class="{
               animation__modal: animated,
             }"
        >
          <div ref="draggableTarget"
               class="modal__draggable-target"
          >
            <Button v-if="closable"
                    ref="closeBtn"
                    type="secondary"
                    hover-style="inverted"
                    icon="cross-modal"
                    class="modal__close"
                    data-test-id="modal-close"
                    @click="close"></Button>

            <div ref="draggableTrigger"
                 class="modal__modal"
                 :data-test-id="dataTestId"
            >
              <div v-if="slots['modal-header'] || closable"
                   ref="header"
                   class="modal__header"
              >
                <slot name="modal-header" />
              </div>
              <div ref="scrollable"
                   class="modal__content"
                   :style="{ 'max-height': maxContentHeight }"
              >
                <div class="modal__overflow-container">
                  <slot />
                </div>
              </div>
              <div v-if="slots['modal-bottom']"
                   ref="bottom"
                   class="modal__bottom"
              >
                <slot name="modal-bottom" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<style lang="scss" scoped>
@import '@/assets/css/mixins/breakpoints-up.scss';
@import '@/assets/css/mixins/styling.scss';

.modal {
  $self: &;
  width: 100%;
  height: 100%;
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  box-sizing: border-box;



    @include lg-down {
      align-items: flex-end;
      padding-top: 7.75rem;
    }
    .modal__close {
      width: 2.5rem !important; // TODO: remove the !important after vite css chunk problem is solved
      height: 2.5rem;
      // margin: 0 0.5rem 0.5rem auto;
      position: absolute !important; // TODO: remove the !important after vite css chunk problem is solved
      top: -3.5rem;
      right: 0.5rem;

      @include lg-up {
        right: 0;
      }
    }

  @include lg-up {
    align-items: center;
    padding-top: 7.75rem;

    .modal__close {
      width: 2.5rem !important; // TODO: remove the !important after vite css chunk problem is solved
      height: 2.5rem;
      position: absolute !important; // TODO: remove the !important after vite css chunk problem is solved
      top: -3.5rem;
      right: 0.5rem;

      @include lg-up {
        right: 0;
      }
    }
  }

  justify-content: center;

  @include lg-up {
    align-items: flex-start;
    padding-bottom: 2rem;
  }

  &__overlay {
    background-color: rgba(36, 46, 74, 0.8);
    width: 100%;
    height: 100%;
    top: 0;
    position: fixed;
    display: flex;
    align-items: flex-end;
    z-index: 1;

    @include lg-up {
      align-items: flex-start;
      justify-content: center;
    }
  }

  &__draggable-target {
    &[draggable-released] {
      transition: transform 0.2s;
    }
  }

  &__container {
    position: relative;
    z-index: 2;
    width: 100%;

    @include lg-up {
      width: 75%;
      max-width: 48rem;
    }
  }


  &__modal {
    background: #fff;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;

    // fixes scrollbar overflow
    overflow: hidden;

    @include lg-up {
      border-radius: 1rem;
    }
  }

  &__header {
    display: flex;
  }

  &__overflow-container {
    width: 100%;
    overflow: hidden;
    padding: 2rem;
  }

  &__content {
    @include bistroo-scrollbar;
    -webkit-overflow-scrolling: touch;
    min-height: 18rem;
    overflow: auto;
  }

  &__bottom {
    padding: 1rem 2rem calc(1rem + env(safe-area-inset-bottom));
    border-top: 1px solid var(--color-neutral-quaternary);
    display: flex;
    gap: 1rem;
    justify-content: space-between;

    &:deep(.button--primary) {
      margin-left: auto;
    }
  }

  &__seo-content {
    display: none;
  }

  &--no-padding {
    #{$self}__overflow-container {
      padding: 0;
    }
  }
}

@keyframes modalInDesktop {
  from {
    opacity: 0;
    transform: scale(0.975);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes modalInMobile {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes overlayIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animation {
  &__modal {
    animation-name: modalInMobile;
    animation-duration: 0.5s;
    animation-timing-function: cubic-bezier(0.1, 0.3, 0.35, 1);

    @include lg-up {
      animation-duration: 0.35s;
      animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
      animation-name: modalInDesktop;
    }
  }

  &__overlay {
    animation-name: overlayIn;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;

    @include lg-up {
      animation-duration: 0.35s;
      animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
    }
  }
}
</style>
